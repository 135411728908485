.modal-header {
  color: white;
  background-image: url("../../assets/img/course1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.modal-header .modal-title {
  margin-left: 120px;
}

.content-holder {
  border: 1px solid red;
}
.left-part.social-media .through-facebook {
  border: 1px solid black;
}
.left-part.social-media .through-google {
  border: 1px solid black;
}
.left-part.social-media .through-linkedin {
  border: 1px solid black;
}
.left-part.social-media .through-facebook img {
  width: 30px;
  height: 30px;
}
.left-part.social-media .through-google img {
  width: 30px;
  height: 30px;
}
.left-part.social-media .through-linkedin img {
  width: 30px;
  height: 30px;
}
.splitter .line-above-or {
  margin-bottom: 45px;
  margin-top: 25px;
  width: 75px;
  border: 1px solid rgb(182, 180, 180);
  transform: rotate(90deg);
}
.splitter .line-below-or {
  margin-top: 35px;
  width: 75px;
  border: 1px solid rgb(182, 180, 180);
  transform: rotate(90deg);
}
.splitter .or-text {
  border: 1px solid rgb(158, 157, 157);
  background-color: #f7b744;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
#login-btn-in-modal {
  background-color: #f7b744;
  font-weight: 400;
  border-radius: 50px;
  color: white;
  border: none;
  padding: 5px;
}
#signup-btn-in-modal {
  background-color: #f7b744;
  font-weight: 400;
  border-radius: 50px;
  color: white;
  border: none;
  padding: 5px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-logo img {
  max-width: 100px;
}

.navbar-menu {
  list-style: none;
  display: flex;
}

.navbar-item {
  margin-right: 20px;
}

.navbar-item:last-child {
  margin-right: 0;
}

.navbar-menu a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.navbar-search {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  background-color: #f5f5f5;
}

.navbar-search input[type="text"] {
  border: none;
  padding: 6px;
  width: 100%;
  outline: none;
}

.navbar-search button {
  background-color: #ff4500;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.navbar-user a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  margin-left: 20px;
}

.navbar-search i {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .navbar-logo img {
    margin-bottom: 20px;
  }

  .navbar-menu {
    flex-direction: column;
  }

  .navbar-item {
    margin: 10px 0;
  }

  .navbar-search {
    margin-top: 20px;
  }

  .navbar-user {
    margin-top: 20px;
  }

  .navbar-user a {
    display: block;
    margin: 10px 0;
  }
}

.navbar-search {
  display: flex;
  border-radius: 20px;
  background-color: #f5f5f5;
  overflow: hidden;
}

.navbar-search input[type="text"] {
  border: none;
  padding: 6px;
  width: 100%;
  outline: none;
  background: transparent;
}

.navbar-search button {
  background-color: transparent;
  color: #ff4500;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.navbar-search button {
  background-color: transparent;
  color: #ff4500;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-search-form {
  display: flex;
  align-items: center;
}

.custom-input {
  border: none;
  border-radius: 20px;
  padding-right: 35px;
  outline: none;
  box-shadow: none;
}

.custom-search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.navbar-nav {
  margin-right: 10px;
}

.navbar-nav li {
  display: flex;
  align-items: center;
  gap: 5;
}

.custom-search-button img {
  background-color: transparent;
}
.cart-counter {
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 80%;
  background-color: #f7b744;
}
