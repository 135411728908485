.darkbackground {
  background-color: #f7f9fa;
}

.Img {
  width: 40mm;
  height: 20mm;
  margin-right: 6mm;
}

.button {
  width: 100%;
}
.fixedcol {
  position: sticky;
  top: 20px;
  right: 20px;
}
