.reasonTitle {
  font-family: "--font-stack-heading-serif";
}

.getstart {
  font-size: 20px;
}

.imgsize1 {
  width: 100px;
  height: 100px;
}
.supportimg {
  width: 300px;
  height: 300px;
}

.instimg {
  max-width: 400px;
  max-height: 400px;
}
.button {
  width: 100%;
}

.darkbackground {
  background-color: #f7f9fa;
}
