@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@400;500;600&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;1,100;1,400;1,500;1,700&display=swap');

*:focus {
  outline: none;
}

.App{
  background-color: #D9D9D9;
  font-family: 'Inter', sans-serif;
}

/* === Navbar css start === */
.navbar{
  width: 100%;
  background-color: #fff;
  border: 1px solid gray;
  /* border: 1px solid red; */
}
.navbar-nav{
 margin-top: 2.5vmin;
 /* border: 1px solid red; */
}
.navbar-nav .nav-item{
  /* border: 1px solid red; */
  margin:0 5px;
}
/* === Navbar css end === */
/* === Logo css start === */
.navbar-brand img{
  width: 148px;
  height: 55px;
}
/* === Logo css end === */
.navbar-nav form{
  border: 1px solid black;
  border-radius: 15px;
  padding: 0 2vmin;
}
.navbar-nav form img{
  width: 16px;
  height: 16px;
}
.navbar-nav form input{
  border: none;
}

.navbar-nav form input:focus{
  outline: 0;
}
.nav-item.teach-on-us{
  /* border: 1px solid red; */
  white-space: nowrap;
}
#login-btn{
  border: 1px solid black;
  width: 107px;
  height: 42px;
  border-radius: 20px;
  text-align: center;
  color: #F6C90E;
  background-color: #000;
}
#signup-btn{
  border: 1px solid black;
  width: 107px;
  height: 42px;
  border-radius: 20px;
  text-align: center;
}
.navbar-nav .nav-item.cart-icon a img{
  width: 30px;
  height: 30px;
  /* border: 1px solid red; */
}
.navbar-nav .nav-item.globe-icon a img{
  /* border: 1px solid red; */
  width: 30px;
  height: 30px;
}
.course-in-cart{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background-color: #F6C90E;
}
/* custom.css */

 /* == Hero Section Start== */
 .hero-section{
  padding: 50px;
  /* border:1px solid red */
 }
 .hero-section .container .row .col-lg-6.hero-text{
  padding: 50px;
  /* border: 1px solid red; */
 }
 .are-you-employee{
  font-weight: bold;
 }

 .buttons-in-hero .create-course-btn{
  color:white;
  padding: 5px 20px;
  border-radius: 20px;
  background-color:#F6C90E
 }
 .buttons-in-hero .create-course-btn:hover{
  color:white;
  padding: 5px 20px;
  border-radius: 20px;
  background-color:#F6C90E
 }
 .buttons-in-hero .need-hand-btn{
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid rgb(41, 39, 39);
  /* border: 1px solid red; */
 }
 .buttons-in-hero .need-hand-btn:hover{
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid rgb(41, 39, 39);
  /* border: 1px solid red; */
 }
 .hero-section .container .row .col-lg-6.hero-img{
  padding: 50px;
  /* border: 1px solid red; */
 }

 /* == Hero section end == */

 /* === Middle section start=== */
 .middle-part{
  padding: 30px 230px 30px 161px;
  background-color: #2A363B;
  color: #F8F6F6;
  border: 1px solid gray;
 }
 .middle-part .container .row{
  padding: 10px;
 }
 .middle-part .container .row .col-lg-3.col-sm-12{
  text-align: center;
  white-space: nowrap;
}
 /* ===Middle section end */
/* ==== Top Course start === */
.top-course{
  /* padding: 100px; */
  background-color: #D9D9D9;
  padding-bottom: 20px;
  /* border: 1px solid red; */
}
.top-course .container .row .col-lg-3 .card{
  background-color: #D9D9D9;
  width: 100%;
  /* height: 500px; */
  border: 1px solid rgb(224, 222, 222);
}
.top-course .container .row .col-lg-3 .card .card-body .card{
 text-decoration: none;
 color: #2A363B;
}
.top-course .container .row .col-lg-3 .card .card-body .course-info{
  font-weight: lighter;
}
.add-to-cart{
  margin-top: 8px;
  padding: 5px;
  /* border: 1px solid red; */
}
.add-to-cart .add-to-cart-btn{
  color:white;
  padding: 10px 20px;
  background-color:#F6C90E;
  border-radius: 50px;
}
.add-to-cart .add-to-cart-btn:hover{
  color:white;
  padding: 10px 20px;
  background-color:#F6C90E;
  border-radius: 50px;
}
.add-to-cart .add-to-cart-btn .cart-icon{
  width: 25px;
  height: 25px;
  color:white;
}
.top-course .container .section-title{
  padding-top: 20px;
  padding-bottom: 20px;
  /* border:1px solid red; */
}
/* ==== Top Course end === */
/* === Why win start === */
.why-win{
  background-color: #fff;
  border-radius: 50px;
}
.why-win-title{
  padding: 50px;
  text-align: center;
}
.why-win-title h3{
  font-weight: 400;
}

/* === Why win end */
/* === Testimonials start ===*/
.testimonials{
  padding: 50px;
  text-align: center;
  /* border: 1px solid black; */
}
#testimonialsCarousel{
  padding: 25px;
  padding-top: 30px;
  /* border: 1px solid red; */
}
.carousel-item{
  width: 50%;
  /* border: 1px solid red; */
}

.carousel-control-prev-icon{
   padding: 20px;
   background-color:#000;
   width: 50px;
   height: 50px;
   border-radius:  50px;
   color: #F6C90E;
   border: 1px solid black;
}
.carousel-control-next-icon{
  padding: 20px;
  background-color:#000;
  width: 50px;
  height: 50px;
  border-radius:  50px;
  color: #F6C90E;
  border: 1px solid black;
}
.indicators{
  background-color: red;
}
#testimonialsCarousel .carousel-control-prev{
  /* border: 1px solid red; */
  padding-right: 100%;
}
#testimonialsCarousel .carousel-control-next{
  padding-left: 100%;
  /* border: 1px solid red; */
}
.overlay {
  padding: 70px;
  border:none;
  background-color: rgba(8, 8, 8, 0.5); /* Adjust the opacity as needed */
}
.text-over-image p{
  width: 200px;
  /* border: 1px solid red; */
}


/* === Testimonials end === */

/* == Footer start ==*/
.footer-section{
  color: white;
  font-weight: 400;
  text-align: center;
  padding: 50px;
  background-color: #2A363B;
}
.logo-at-footer img{
  margin-bottom: 10px;
  width: 255px;
  height: 96px;
  /* border: 1px solid red; */
}
.footer-section .container{
  padding-bottom: 50px;
  border-bottom: 1px solid #9D5656;
}
/* .carousel-indicators{
  background-color: red;
  border: 1px solid red;
} */


/* == Footer end == */

.carousel-indicators {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.indicator-dot {
  width: 50vmin;
  height: 50vmin;
}

.indicator-dot.active {
  background-color: #333;
}
/* ===== Style for Course Details page ===== */
.all-course-details-container{
  padding: 20px;
  /* border: 1px solid red; */
}

.instructor-details img{
  /* border: 1px solid red; */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.course-requirement{
  /* border: 1px solid red; */
  padding: 10px;
}
.course-requirement .req-list li{
  position: relative;
  list-style-type: none;
}
.course-requirement .req-list li::before{
  content: '.';
  width: 20px;
  position: absolute;
  left: 0;
}
/* Custom CSS */
.embed-responsive-16by9 {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height divided by width) */
  height: 0;
  overflow: hidden;
}

.embed-responsive-16by9 iframe,
.embed-responsive-16by9 video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.course-enrolled{
  padding: 20px;
  border-bottom:1px solid #9D5656;
}
.all-enrolled-courses .col-md-4 .card .card-body{
  background-color: rgb(66, 65, 65);
  color: white;
}
.list-of-course-enrolled .filter-in-page-using input{
  border-radius: 10px;
  border: none;
  padding: 5px;
  border: 1px solid #9D5656;
}

.all-enrolled-courses .col-lg-3 .card{
  border: 1px solid #9D5656;
  background-color: #D9D9D9;
}
.pagination .page-item .page-link{
  border: none;
}
.pagination .page-item.active .page-link{
  background-color: #F6C90E;
}
.pagination .page-item.disabled.prev .page-link{
  color: #F6C90E;
}
.pagination .page-item.next .page-link{
  color: #F6C90E;
}
.resources .list-group-item .ppt-icon{
  color: #F6C90E;
}
.resources .list-group-item .pdf-icon{
  color: #F6C90E;
}
.resources .list-group-item .zip-icon{
  color: #F6C90E;
}
.resources .list-group-item a{
  color: #9D5656;
}
.all-contents .container #courseAccordion .card .card-header .btn-link{
  color: #9D5656;
}
.your-progress .certificate-btn{
  background-color:black;
  color: #F6C90E;
}
.your-progress button:hover{
  color: #F6C90E;
  background-color:black;
}

@media screen and (max-width:576px) {
  .hero-section ,.container .hero-text{
   width: 100%;
  }
} 
/* === Loader */
.loader-div{
  background-color: white;
  padding-bottom: 100rem;
}
#three-dot{
  color: #F6C90E;
}
/* === fixed error in css === */
.course-title-in-card{
  max-height: 20px; 
  overflow: hidden;
  text-overflow: ellipsis;
}
/* ====== New Hero style === */

/* Hero Video Section */
.hero-video {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hero-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 20px; /* Add padding to the content for better visibility */
  border-radius: 10px; /* Rounded corners for the content */
}

.hero-content h1 {
  font-size: 36px;
  margin-bottom: 20px; /* Add some space between the heading and the motto */
}

.hero-content p {
  font-size: 18px;
  margin-bottom: 30px; /* Add space between the motto and the button */
}

/* Style the button */
#get-started-in-hero{
  background-color: black; /* Golden color */
  color:#FFD700;
  border-radius: 20px; /* Rounded corners for the button */
  padding: 10px 20px; /* Adjust padding for button size */
  font-size: 18px;
  border: none; /* Remove button border */
}

/* Adjust button on hover */
#get-started-in-hero:hover {
  background-color: black; /* Golden color */
  color:#FFD700;/* Darker golden color on hover */
  transition: background-color 0.3s ease-in-out;
}
/* === New Middle Part === */

/* Counter Section */

.counter-section {
  background-color: #2A363B;
  color: #F8F6F6;
  padding: 50px 0;
}

.counter {
  text-align: center;
  margin-bottom: 30px;
}

.counter .count {
  font-size: 36px;
  font-weight: bold;
}

.counter p {
  font-size: 18px;
}

/* Animation */
.animated {
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

@keyframes count-up {
  from {
      transform: translateY(10px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}
/* === New Why we win === */

/* Why We Win Section */
.why-we-win-section {
  background-color: #fff; /* Set your preferred background color */
  padding: 50px 0;
  border-radius: 10px;
}

.reasons {
  padding: 20px;
}

.reasons h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.reasons ul {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 18px;
}

.illustration img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Responsive adjustments as needed */

