.Img {
  width: 40mm;
  height: 20mm;
  margin-right: 6mm;
}
.txt {
  font-size: 0.7em;
  font-weight: bold;
}
.txt2 {
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  font-size: 1.6rem;
}
.txt3 {
  line-height: 1.2;
  letter-spacing: -0.02rem;
  font-size: 0.8em;
}
